@import url("~leaflet/dist/leaflet.css");
@font-face {
  font-family: "Montserrat";
  src: url(./fonts/Montserrat-Regular.ttf) format("truetype");
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.leaflet-container {
  height: 100%;
}
.form-label {
  font-size: 0.8888888888888888rem;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 1.5;
}
select option {
  zoom: 1.25;
}

.large-width.input-field {
  padding: 12px;
  border: 0.5px solid lightgray;
}

.patient-form > .some-margin {
  display: none;
}

label:hover {
  cursor: pointer;
}

iframe {
  z-index: -1 !important;
}

.patient-kobo-form .MuiFormControl-root > label {
  font-size: 14px;
}

.bordered-table {
  margin-bottom: 0 !important;
}

.bordered-table td,
.bordered-table th {
  border-left: 1px solid #000000 !important;
}

.bordered-table tr td,
.bordered-table tr th {
  border-top: 1px solid #000000 !important;
}

.bordered-table td:last-child,
.bordered-table th:last-child {
  border-right: 1px solid #000000 !important;
}

.border-forms {
  border: 1px solid #000000 !important;
}

.text-8px {
  font-size: 8px;
}

@media print {
  .print-content {
    margin: 20mm;
  }
}

@layer base {
  .opacity-10 {
    @apply opacity-10;
  }
  .opacity-100 {
    @apply opacity-100;
  }

  .simple-table {
    @apply w-full;
  }
  .simple-table table {
    @apply w-full border;
  }
}
.fc-toolbar-title {
  @apply font-bold text-lg text-primary-dark;
}
