@import url("https://cdn-uicons.flaticon.com/uicons-regular-rounded/css/uicons-regular-rounded.css");
@import url("https://cdn-uicons.flaticon.com/uicons-solid-rounded/css/uicons-solid-rounded.css");
@import url("https://cdn-uicons.flaticon.com/uicons-bold-rounded/css/uicons-bold-rounded.css");
@import url("https://cdn-uicons.flaticon.com/uicons-solid-straight/css/uicons-solid-straight.css");
@import url("https://cdn-uicons.flaticon.com/uicons-regular-straight/css/uicons-regular-straight.css");
@import url("https://cdn-uicons.flaticon.com/uicons-bold-straight/css/uicons-bold-straight.css");
.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@media print {
	.gtc-hideonprint {
		display: none !important;
	}
	.gtc-showonprint {
		/* width: calc(100vw - 100px); */
		width: auto !important;
		display: block !important;
	}

	.d-print-block {
		display: block !important;
	}
	.d-print-none {
		display: none !important;
	}

	.fixedbottom-onprint {
		position: absolute !important;
		bottom: 0 !important;
		right: 0;
		margin-bottom: 10 !important;
	}
}

.gtc-viewimage img {
	transform: scale(0.5) !important;
}

.react-transform-component img {
	width: 40vw !important;
}
.gtc-viewimage .TransformComponent-module_container__3NwNd {
	height: calc(100vh - 50px);
	position: relative;
	width: auto !important;
	background-color: rgb(58, 58, 58) !important;
	padding: 5px;
}
.cursor-drag {
	cursor: grab !important;
}

#draggable-handle {
	cursor: move;
}

.gtc-capitalize {
	text-transform: capitalize;
}

.gtc-uppercase {
	text-transform: uppercase !important;
}

.cursor-pointer {
	cursor: pointer !important;
}

.gtc-textfield-noborder .MuiOutlinedInput-notchedOutline {
	border-color: #f3f3f3 !important;
}

.Toastify__toast--default {
	border-radius: 0.2em !important;
	background: #002f65e0 !important;
	color: #fff !important;
}

.Toastify__close-button--default {
	color: #f6f6f6 !important;
	opacity: 1 !important;
}

.textUppercase {
	text-transform: uppercase;
}

.ct-series-a .ct-line {
	stroke: blue !important;
	stroke-width: 4px;
	background-color: transparent !important;
}

.ct-series-a .ct-point {
	stroke: blue !important;
	background-color: transparent !important;
	stroke-width: 10px;
}

.darken-inputvalue input {
	font-weight: bold;
}

@page {
	size: auto !important;
}

@media print {
	.hide-on-print {
		display: none !important;
	}
}

.text-center > .MuiBox-root {
	display: flex;
	align-items: center;
	justify-content: center;
}
