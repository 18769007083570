@import url(https://cdn-uicons.flaticon.com/uicons-regular-rounded/css/uicons-regular-rounded.css);
@import url(https://cdn-uicons.flaticon.com/uicons-solid-rounded/css/uicons-solid-rounded.css);
@import url(https://cdn-uicons.flaticon.com/uicons-bold-rounded/css/uicons-bold-rounded.css);
@import url(https://cdn-uicons.flaticon.com/uicons-solid-straight/css/uicons-solid-straight.css);
@import url(https://cdn-uicons.flaticon.com/uicons-regular-straight/css/uicons-regular-straight.css);
@import url(https://cdn-uicons.flaticon.com/uicons-bold-straight/css/uicons-bold-straight.css);
@media print {
  .page-break {
    page-break-after: always !important;
  }
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		-webkit-animation: App-logo-spin infinite 20s linear;
		        animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
	from {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes App-logo-spin {
	from {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@media print {
	.gtc-hideonprint {
		display: none !important;
	}
	.gtc-showonprint {
		/* width: calc(100vw - 100px); */
		width: auto !important;
		display: block !important;
	}

	.d-print-block {
		display: block !important;
	}
	.d-print-none {
		display: none !important;
	}

	.fixedbottom-onprint {
		position: absolute !important;
		bottom: 0 !important;
		right: 0;
		margin-bottom: 10 !important;
	}
}

.gtc-viewimage img {
	-webkit-transform: scale(0.5) !important;
	        transform: scale(0.5) !important;
}

.react-transform-component img {
	width: 40vw !important;
}
.gtc-viewimage .TransformComponent-module_container__3NwNd {
	height: calc(100vh - 50px);
	position: relative;
	width: auto !important;
	background-color: rgb(58, 58, 58) !important;
	padding: 5px;
}
.cursor-drag {
	cursor: grab !important;
}

#draggable-handle {
	cursor: move;
}

.gtc-capitalize {
	text-transform: capitalize;
}

.gtc-uppercase {
	text-transform: uppercase !important;
}

.cursor-pointer {
	cursor: pointer !important;
}

.gtc-textfield-noborder .MuiOutlinedInput-notchedOutline {
	border-color: #f3f3f3 !important;
}

.Toastify__toast--default {
	border-radius: 0.2em !important;
	background: #002f65e0 !important;
	color: #fff !important;
}

.Toastify__close-button--default {
	color: #f6f6f6 !important;
	opacity: 1 !important;
}

.textUppercase {
	text-transform: uppercase;
}

.ct-series-a .ct-line {
	stroke: blue !important;
	stroke-width: 4px;
	background-color: transparent !important;
}

.ct-series-a .ct-point {
	stroke: blue !important;
	background-color: transparent !important;
	stroke-width: 10px;
}

.darken-inputvalue input {
	font-weight: bold;
}

@page {
	size: auto !important;
}

@media print {
	.hide-on-print {
		display: none !important;
	}
}

.text-center > .MuiBox-root {
	display: flex;
	align-items: center;
	justify-content: center;
}

.d-profile{
    max-width: 200px;
    border-radius: 50%;
}

.d-patient-category{
    max-width: 90vw;
    overflow-y: auto;
}

.d-patient-category::-webkit-scrollbar {
    height: 5px;
}

.d-patient-category::-webkit-scrollbar-thumb {
    background: #cecece ;
    border-radius: 1px;
}
.d-patient-category .category-btn{
    background-color: #fff !important;
    border: none !important;
    color: #67727a !important;
}

.d-patient-category .active-btn{
    border: none !important;
    background-color: #fff !important;
    color: #3275a7 !important;
}
.d-hover:hover{
    background-color: #f8f8f8;
    cursor: pointer;
}
    /* width
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  /* ::-webkit-scrollbar-track {
    background: #f1f1f1;
  } */
  
  /* Handle */
  /* ::-webkit-scrollbar-thumb {
    background: #888;
  } */
  
  /* Handle on hover */
  /* ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }  */


  /* doctors side bar */
  .sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }
  
  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }
  
  .sidenav a:hover {
    color: #f1f1f1;
  }
  
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  
  #main {
    transition: margin-left .5s;
    padding: 16px;
  }
  
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }
 

  .d-newdiagnosis-form .ql-editor {
    min-height: 14em !important;
  } 

  .d-newdiagnosis-form  .css-2b097c-container {
    position: relative;
    box-sizing: border-box;
    z-index: 900;
  }

  .gtc-viewimage .TransformComponent-module_container__3NwNd {
    height: calc(100vh - 50px);
    position: relative;
    width: auto !important; 
    background-color: rgb(58, 58, 58) !important;
    padding: 5px;
  }   

  .gtc-simg-hover{ 
    z-index: 999;
    position: absolute;
    /* background-color: rgba(236, 236, 236, 0.404);  */
  }

  /* .gtc-drawingcanvas{ 
    cursor: url('./icons/cursor-brush.png') 15 15, auto !important;
  }

  .gtc-erasecanvas{ 
    cursor: url('./icons/cursor-ebrush.png') 15 15, auto !important;
  } */

.fc-list-event-dot,
.fc-daygrid-event-dot {
    display: none !important;
}
 
.fc-daygrid-dot-event .fc-event-title { 
  font-weight: normal !important;
  text-transform: capitalize !important;
}

@media only screen and (max-width: 500px){
  .fc .fc-toolbar-title {
    font-size: 1rem !important; 
  }
} 

#dailyCalendar .fc table{
  font-size: 1rem;
  cursor: pointer; 
} 

#dailyCalendar .fc .fc-col-header-cell-cushion{
  display: block !important;
  padding: 10px 10px !important;
  text-align: left !important;
  font-weight: 500 !important;
  cursor: default;
  font-size: 14px;
}

#dailyCalendar .fc .fc-event-time{ 
  margin-left: 10px;
  margin-right: 10px;
} 
 

.ct-chart .ct-series.ct-primary .ct-line {
  stroke: #007bff;  
  stroke-width: 2px;
 } 

 .ct-chart .ct-series.ct-primary .ct-point {
  stroke: #007bff;   
  stroke-width: 8px; 
  stroke-linecap: circle;
 }


 .ct-chart .ct-series.ct-secondary .ct-line {
  stroke: #dc3545 ; 
  stroke-width: 2px;
 }
 

 .ct-chart .ct-series.ct-secondary .ct-point {
  stroke: #dc3545; 
  stroke-width: 8px; 
  stroke-linecap: circle;
 }
 
 
.ct-chart {
  position: relative;
}

.ct-tooltip {
  position: absolute;
  display: inline-block;
  min-width: 5em;
  padding: 8px 10px;
  border-radius: 5px;
  background: #383838;
  color: #fff;
  text-align: center;
  pointer-events: none;
  z-index: 100;
  transition: opacity .2s linear;
}

.ct-tooltip:before {
  position: absolute;
  bottom: -14px;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  pointer-events: none;
  border-color: rgba(251, 249, 228, 0);
  border-top-color: #383838;
  border-width: 7px;
  margin-left: -8px;
}

.ct-tooltip.hide {
  display: block;
  opacity: 0;
  visibility: hidden;
}   
 
.chartWrapper {
  position: relative;
}

.chartWrapper > canvas {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none; 
}

/* .chartAreaWrapper {
  width: 100%;
  overflow-x: scroll !important;
} */

#myChartAxis{    
  display: none;
}


@font-face {
  font-family: "Montserrat";
  src: url(/static/media/Montserrat-Regular.3cd78665.ttf) format("truetype");
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.leaflet-container {
  height: 100%;
}
.form-label {
  font-size: 0.8888888888888888rem;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 1.5;
}
select option {
  zoom: 1.25;
}

.large-width.input-field {
  padding: 12px;
  border: 0.5px solid lightgray;
}

.patient-form > .some-margin {
  display: none;
}

label:hover {
  cursor: pointer;
}

iframe {
  z-index: -1 !important;
}

.patient-kobo-form .MuiFormControl-root > label {
  font-size: 14px;
}

.bordered-table {
  margin-bottom: 0 !important;
}

.bordered-table td,
.bordered-table th {
  border-left: 1px solid #000000 !important;
}

.bordered-table tr td,
.bordered-table tr th {
  border-top: 1px solid #000000 !important;
}

.bordered-table td:last-child,
.bordered-table th:last-child {
  border-right: 1px solid #000000 !important;
}

.border-forms {
  border: 1px solid #000000 !important;
}

.text-8px {
  font-size: 8px;
}

@media print {
  .print-content {
    margin: 20mm;
  }
}

@layer base {
  .opacity-10 {
    @apply opacity-10;
  }
  .opacity-100 {
    @apply opacity-100;
  }

  .simple-table {
    @apply w-full;
  }
  .simple-table table {
    @apply w-full border;
  }
}
.fc-toolbar-title {
  @apply font-bold text-lg text-primary-dark;
}

